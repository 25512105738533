import React from "react";
import { Helmet } from "react-helmet-async";

const preTags = ["", "twitter:", "og:"];

export default function HelmetComponent({ title, description }) {
  return (
    <>
      {preTags.map((tag, index) => {
        if (tag !== "") {
          return (
            <Helmet key={"HelmetMeta" + title + index}>
              <meta name={tag + "title"} content={title} />
              <meta name={tag + "description"} content={description} />
            </Helmet>
          );
        } else {
          return (
            <Helmet key={"HelmetMeta" + title + index}>
              <title>{title}</title>
              <meta name="title" content={title} />
              <meta name="description" content={description} />
            </Helmet>
          );
        }
      })}
    </>
  );
}
