import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Link, redirect, useNavigate } from "react-router-dom";
import { browserHistory } from "react-router";
import BaseButtonPublic from "../Buttons/BaseButtonPublic";
import {
  HIGHLIGHT_REMOVED,
  NAVBAR_HEIGHT,
  TEXT_NOT_SELECTABLE,
  VERTICAL_SPACE,
  baseBlue,
  baseRed,
  darkBlue,
  darkGrey,
  lightOrange,
  yellow,
} from "../../graphic/theme";

import { useMediaQuery } from "@mui/material";
import { theme } from "../../../App";
import MobileDrawerMenu from "./MobileDrawer";
import LinkContainer from "./LinkContainer";

export default function Navbar({ references, notTransparent = false }) {
  const [state, setState] = React.useState(false);
  const [buttonHover, setButtonHover] = React.useState(null);
  const [bgTransparent, setBgTransparent] = React.useState(false);
  const navigate = useNavigate();
  const sandwichMenu = useMediaQuery(theme.breakpoints.down("md"));
  let mobile = useMediaQuery(theme.breakpoints.only("xs"));

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window : undefined,
  });

  React.useEffect(() => {
    if (!state) {
      setTimeout(() => {
        setBgTransparent(false);
      }, 250);
    } else {
      setBgTransparent(true);
    }
  }, [state]);

  React.useEffect(() => {
    if (window && sandwichMenu && state) {
      window.onpopstate = (e) => {
        setState(false);
      };
    }
  });

  return (
    <header>
      <Box
        sx={{
          height: `fit-content`,
          width: "100%",
          bgcolor:
            elevated || bgTransparent || mobile || notTransparent
              ? "rgba(254, 158, 1,0.92)"
              : "transparent",
          position: "fixed",
          top: 0,
          zIndex: 10000,
          boxShadow:
            elevated || state || mobile
              ? "0px 2px 5px 0px rgba(0,0,0,0.2)"
              : "none",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            px: "0px !important",

            //zIndex: zindexPublic.navbar,
            height: `fit-content`,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: `${NAVBAR_HEIGHT}px`,
              py: "0px",
              pl: VERTICAL_SPACE,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                mr: "auto",
              }}
            >
              <Box
                component={Link}
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                  p: "10px",
                  pl: "0px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                  ...HIGHLIGHT_REMOVED,
                }}
                onClick={() => {
                  navigate("/");
                }}
                to="/"
              >
                <Box
                  style={{
                    display: "flex",
                    height: "38px",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="logo"
                    sx={{
                      mt: "2px",
                      ml: "1px",
                      height: "100%",
                      color: darkGrey,
                      height: "fit-content",
                      ...TEXT_NOT_SELECTABLE,
                    }}
                  >
                    bagr-brno.cz
                  </Typography>
                </Box>
              </Box>
              {!mobile && (
                <Box
                  sx={{
                    height: "26px",
                    ml: "15px",
                    mr: "10px",
                    width: "2px",
                    bgcolor: darkGrey,
                  }}
                />
              )}

              {!mobile && (
                <>
                  <Box
                    key={"NavbarLink1"}
                    component={Link}
                    sx={{
                      textDecoration: "none",
                      mx: "3px",
                      px: "10px",
                      py: "8px",
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/cenik");
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", mt: "2px" }}
                    >
                      Ceník
                    </Typography>
                  </Box>
                  <Box
                    key={"NavbarLink2"}
                    component={Link}
                    sx={{
                      textDecoration: "none",
                      mx: "3px",
                      px: "10px",
                      py: "8px",
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/kontakt");
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", mt: "2px" }}
                    >
                      Kontakt
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                height: "100%",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                ml: "auto",
              }}
            >
              <LinkContainer
                sx={{ height: "100%", width: "100%" }}
                blank={false}
                url={"tel:797996873"}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    bgcolor: buttonHover === "tel" ? yellow : darkGrey,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onMouseEnter={() => {
                    setButtonHover("tel");
                  }}
                  onMouseLeave={() => {
                    if (buttonHover === "tel") {
                      setButtonHover(null);
                    }
                  }}
                >
                  <Typography
                    variant="navButton"
                    sx={{
                      color: buttonHover === "tel" ? darkGrey : "white",
                      px: "20px",
                    }}
                  >
                    797 996 873
                  </Typography>
                </Box>
              </LinkContainer>
            </Box>
            {sandwichMenu && (
              <Box
                sx={{
                  px: "8px",
                  height: "100%",
                  bgcolor: mobile ? "transparent" : "rgba(254, 158, 1,0.92)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MobileDrawerMenu
                  references={references}
                  state={state}
                  setState={setState}
                />
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </header>
  );
}
