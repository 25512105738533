import React, { lazy } from "react";

const BagrBrno = lazy(() => import("../pages/BagrBrno"));
const CenikPage = lazy(() => import("../pages/Ceniky/CenikPage"));
const OchranaSoukromi = lazy(() => import("../pages/Legal/OchranaSoukromi"));
const Kontakt = lazy(() => import("../pages/Legal/Kontakt"));

let baseRoutes = [
  {
    path: "/",
    element: <BagrBrno />,
    title:
      "Zemní a výkopové práce pro Brno a okolí | Kvalitně a spolehlivě | www.bagr-brno.cz",
    description:
      "Zajišťujeme výkopové zemní práce velkých i malých rozměrů. Naše služby vám předem nezávazně naceníme. Kvalita a spolehlivost je u nás na prvním místě. Odpovědná osoba: Ing. Petr Charvát.",
  },
  {
    path: "/cenik",
    element: <CenikPage />,
    notTransparent: true,
    title: "Ceník | Zemní a výkopové práce pro Brno a okolí | www.bagr-brno.cz",
    description:
      "Hodinové sazby, smluvní ceny, individuální nacenění. Odpovědná osoba: Ing. Petr Charvát; IČO: 15564673; DIČ: CZ6502200309; Sídlo: Högrova 2862/2; 612 00 Brno, Královo Pole.",
  },
  {
    path: "/kontakt",
    element: <Kontakt />,
    notTransparent: true,
    title: "Kontakt | www.bagr-brno.cz",
    description:
      "Odpovědná osoba: Ing. Petr Charvát; IČO: 15564673; DIČ: CZ6502200309; Sídlo: Högrova 2862/2; 612 00 Brno, Královo Pole. ",
  },
  {
    path: "/soukromi",
    element: <OchranaSoukromi />,
    notTransparent: true,
    title: "Ochrana soukromí | www.bagr-brno.cz",
    description:
      "Odpovědná osoba: Ing. Petr Charvát; IČO: 15564673; DIČ: CZ6502200309; Sídlo: Högrova 2862/2; 612 00 Brno, Královo Pole. ",
  },
];

const routes = [
  ...baseRoutes,
  {
    path: "*",
    element: <BagrBrno />,
    title:
      "Zemní a výkopové práce pro Brno a okolí | Kvalitně a spolehlivě | www.bagr-brno.cz",
    description:
      "Zajišťujeme výkopové zemní práce velkých i malých rozměrů. Naše služby vám předem nezávazně naceníme. Kvalita a spolehlivost je u nás na prvním místě. Odpovědná osoba: Ing. Petr Charvát.",
  },
];
export default routes;
