import React from "react";
import { Box, Typography } from "@mui/material";
import { Motion } from "../Base/Motion";
import { NOT_SELECTABLE } from "../../graphic/theme";

export default function BaseButtonPublic({
  sxProps = {},
  sxText = {},
  text = "",
  outlined = false,
  color,
  onClick,
}) {
  return (
    <Motion>
      <Box
        sx={{
          bgcolor: outlined ? "transparent" : color,
          border: `3px solid ${color}`,
          height: "42px",
          width: "fit-content",
          borderRadius: "42px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          ...NOT_SELECTABLE,
          ...sxProps,
        }}
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        <Typography
          variant="body1"
          sx={{
            mx: "25px",
            color: "white",
            fontWeight: "bold",
            lineHeight: "14px",
            ...sxText,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Motion>
  );
}
