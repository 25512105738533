import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { lightOrange } from "../../graphic/theme";

export default function LoadingForced({ sx = {} }) {
  return (
    <div>
      <Backdrop
        sx={{
          //color: "#fbfbfb",
          backgroundColor: lightOrange,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          ...sx,
        }}
        open={true}
      >
        <CircularProgress sx={{ color: "#fbfbfb" }} />
      </Backdrop>
    </div>
  );
}
